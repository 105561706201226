@import "../../style/sass/main.scss";

button.btn {
  font-family: $familyMontserrat;
  min-height: 3.52rem;
  max-height: 3.52rem;
  font-weight: $semiBold;
  line-height: 1rem;
  font-size: 1rem;
  border-radius: 0.625rem;
  padding: 0.9375rem 1.1875rem;
}

.primary-button {
  background: $primary-color;
  color: $white-color;

  &:disabled {
    color: $text-disabled;
    background-color: $link-color-1;
    border: 0.063rem solid $grey-2;
  }
}

.primary-button.start {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 0.4rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.primary-button.end {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    margin-left: 0.4rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.cancel-button {
  border: 0.063rem solid $primary-color;
  color: $primary-color;
}

.cancel-button.start {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 0.4rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.secondary-button {
  background: $link-color-1;
  color: $primary-color;

  &:disabled {
    color: $text-disabled;
    border: 0.063rem solid $grey-2;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.125rem;
    margin-top: -0.375rem;
  }
}

.refresh-btn {
  min-height: 3.2rem;
  max-height: 3.2rem;
  background: $link-color-1;
  color: $primary-color;

  &:disabled {
    color: $primary-color;
    border: 0.063rem solid $grey-2;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.125rem;
    margin-top: -0.375rem;
  }
}

.spinbtn {
  i {
    -webkit-animation: spinbtn 2s linear infinite;
    -moz-animation: spinbtn 2s linear infinite;
    animation: spinbtn 2s linear infinite;
  }
}

@-moz-keyframes spinbtn {
  100% {
    -moz-transform: rotate(-360deg);
  }
}

@-webkit-keyframes spinbtn {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes spinbtn {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

.close-btn {
  width: 2rem;
  height: 2rem;
  border: none;
  background: none;
  outline: none;
}

.copy {
  border: 0.063rem solid $copy-color !important;
  color: $copy-color !important;
}

.compare-save {
  border: 0.063rem solid $copy-color !important;
  background-color: $copy-color;
}

.status-freeze {
  border: 0.063rem solid $case-freeze-text !important;
  color: $case-freeze-text !important;
  background-color: $case-freeze;
}

.status-unfreeze {
  border: 0.063rem solid $case-unfreeze-text !important;
  color: $case-unfreeze-text !important;
  background-color: $case-unfreeze;
}

.status-new {
  border: 0.063rem solid $status-blue !important;
  color: $status-blue !important;
  background: #e9f0f7;
}

.text-button {
  color: $primary-color;
  text-decoration: underline;
  white-space: nowrap;
  padding-left: 0rem !important;
}

.freeze {
  border: 0.063rem solid $case-freeze-text !important;
  color: $case-freeze-text !important;
}

.grey-background-button {
  background: $border-color !important;
  border-radius: 1.25rem !important;
  border: none !important;
  padding: 0.188rem 0.525rem !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  height: auto !important;
}
@import "../../style/sass/main.scss";

.custom-group-filter-dropdown {
  min-width: 12rem;
  max-width: 12rem;

  .input-group-filter-select {
    border-color: $grey-1;
    border-width: 0.1rem;
    border-style: solid;
    font-size: 1rem;
    line-height: 3rem;
    padding: 0.2rem 2.3rem 0 1.6rem;
    border-radius: 0.4rem;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    display: flex;

    label {
      height: 3.2rem;
    }

    label.selected {
      color: $grey-1;
    }

    svg {
      position: absolute;
      user-select: none;
      width: 1em;
      height: 1em;
      display: inline-block;
      fill: currentColor;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      font-size: 1.5rem;
      right: 0.438rem;
      top: calc(50% - 0.5em);
      pointer-events: none;
      color: rgba(0, 0, 0, 0.54);
    }

    svg.active {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }

  .input-group-filter-select.disabled-input-group {
    background-color: $grey-6;
    color: $text-disabled !important;
    cursor: context-menu;

    label {
      -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
      cursor: context-menu;
    }
  }

  .input-group-filter-dropbox {
    margin-top: 0rem;
    min-width: 15.75rem;
    box-shadow: 0 0.313rem 0.313rem -0.313rem rgba(0, 0, 0, 0.2),
      0 0.5rem 0.625rem 0.063rem rgba(0, 0, 0, 0.14),
      0 0.188rem 0.875rem 0.125rem rgba(0, 0, 0, 0.12);
    border-radius: 0.25rem;
    background-color: #fff;

    .input-group-group-list-container {
      padding: 1rem;
      max-height: 12.5rem;
      overflow-y: auto;

      .input-group-group-list {
        margin-bottom: 0.625rem;

        label.group-header {
          padding: 0.5rem 0;
          color: $text-color;
          font-weight: $bold;
        }

        ul {
          padding: 0;
          margin: 0;
          list-style: none;

          li {
            span:nth-child(2) {
              color: $text-color;
            }

            label {
              display: block;
              cursor: pointer;

              svg {
                color: $text-disabled;
              }

              .Mui-checked {
                svg {
                  color: $primary-color;
                }
              }
            }
          }

          li.disabled {
            cursor: no-drop;

            label {
              cursor: no-drop;

              svg {
                color: $grey-7;
              }

              span:nth-child(2) {
                color: $grey-7;
              }
            }
          }
        }
      }

      .input-group-group-list:last-child {
        margin-bottom: 0;
      }
    }

    .input-group-group-list-footer {
      padding: 0.5rem 1rem;
      border-top: 0.063rem solid $border-color;
      display: flex;
      gap: .5rem;

      button {
        min-height: 2.5rem;
        max-height: 2.5rem;
        line-height: 0.5rem;
      }
    }
  }
}

.customeSeperator {
  margin-left: 1.8rem;
  align-self: stretch;
  width: 0.13rem;
  background-color: rgb(204, 204, 204);
  margin-bottom: 7% !important;
  margin-top: 7% !important;
  box-sizing: border-box;
}

.group-by-filter-popper {
  z-index: 5;
}
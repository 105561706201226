@import "../../style/sass/main.scss";
@import "../../style/sass/colors";

.upload-dialog-container {
    .MuiDialog-paper {
        max-width: 45rem !important;
        border-radius: 2rem;
    }

    .upload-button {
        display: flex;
        justify-content: flex-end;
        padding: 1rem 2rem 0 2rem;
    }

    .dialog-content {
        padding: 1rem 0rem 1rem 0rem;

        .dialog-box {
            display: flex;
            flex-direction: row-reverse !important;
            padding: 0 1.5rem;
        }

        .upload-container {
            border: 0.125rem dashed;
            border-radius: 0.625rem;
            color: $border-color2;
            padding: 1.25rem;
            margin: 2.5rem;
            text-align: center;

            ul {
                list-style-type: none;
                padding: 0;
            }

            li {
                color: $status-green;
            }

            .title-message {
                color: $primary-color;
                font-weight: bold;
                text-align: center;
                font-size: 2.2rem;
                margin: 1rem 0;
            }

            .sub-message {
                color: $border-color2;
                font-weight: lighter;
                font-size: 0.875rem;
                text-align: center;
            }
        }
    }
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
}

html {
  font-size: 65%; // 1rem = 12px

  @media only screen and (min-width: 1600px) {
    font-size: 100%;
  }
}

body {
  box-sizing: border-box;
  position: relative;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #939598;
}

/* CSS variable */
$familyMontserrat: 'Montserrat', sans-serif;
$zindex: 9;

// Font weights
$regular: 400;
$medium: 500;
$semiBold: 600;
$bold: 700;

a {
  text-decoration: none;
}

.toaster-container {
  margin-top: 10rem;
  margin-right: 5rem;
  font-size: 1rem;
}
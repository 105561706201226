@import "../../style/sass/main.scss";

.forecast-status {
  border-radius: 0.313rem;
  display: block;
  padding: 0.2rem 0.6rem;
  text-transform: capitalize;
  box-shadow: 0px 0.125rem 0.125rem rgba($color: $black, $alpha: 0.1);
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
}

.status-open {
  background: $primary-color;
  color: $white-color;
}

.status-reviewed {
  background: $grey-5;
  color: $grey-1;
}

.status-closed {
  background: $status-closed;
  color: $white-color;
}


@import "../../style/sass/main.scss";

.custom-select-container {
  font-family: $familyMontserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;

  .custom-select__placeholder,
  .custom-select__single-value {
    margin-top: -0.2rem !important;
  }

  .custom-select__indicator {
    margin-top: -0.15rem;
  }

  .custom-select__control--is-focused {
    min-width: 12rem !important;
  }

  .custom-select__control {
    max-height: 3.52rem !important;
    min-width: 12rem !important;
    min-height: unset !important;
  }

  .custom-select__placeholder {
    font-family: $familyMontserrat;
    color: $grey-1;
  }

  .custom-select__placeholder,
  .custom-select__single-value {
    font-size: 0.875rem !important;
    color: $grey-1;
    font-family: $familyMontserrat;
  }

  .custom-select__menu {
    z-index: 10000;
  }

  .custom-select__value-container {
    padding-top: 0;
    padding-bottom: 0;
  }

  // .custom-select__input {
  //   display: none;
  // }

  .custom-select__indicator-separator {
    margin-top: 18% !important;
    margin-bottom: 23% !important;
    width: 0.11rem;
  }

  .custom-select__control {
    border-radius: 0.5rem !important;
    border: 0.1rem solid $grey-1 !important;
    box-shadow: none !important;
  }

  .custom-select__control--is-disabled {
    background-color: $grey-6 !important;
  }

  .custom-select__value-container {
    height: 3.4375rem;
    padding-left: 0.875rem;
    padding-right: 0.875rem;

    .custom-select__multi-value__label {
      background-color: $table-header-1;
    }

    .custom-select__multi-value__remove {
      background-color: $table-header-1;

      &:hover {
        background-color: $role-permission-cross-background;
        color: $primary-color;
      }
    }
  }

  .custom-select__value-container::-webkit-scrollbar {
    width: 0.313rem;
  }

  .custom-select__placeholder {
    color: $grey-1;
    font-family: $familyMontserrat;
  }

  .custom-select__single-value {
    font-size: 0.875rem;
    color: $grey-1;
    font-family: $familyMontserrat;
  }

  .custom-select__menu {
    z-index: 100 !important;
    margin-top: 0 !important;

    .custom-select__option--is-focused:active {
      background-color: $grey-3;
    }

    .custom-select__option--is-selected {
      background-color: $primary-color;
    }

    .css-10wo9uf-option:active {
      background-color: white;
    }
  }

  .custom-select__menu-list {
    padding: 0;
  }
}
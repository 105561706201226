@import "../../style/sass/main.scss";

.loading-light,
.error-light,
.success-light {
  z-index: 0;
}

.loading-light {
  &::before,
  &::after {
    background: linear-gradient(
      45deg,
      yellow,
      #abc862,
      yellow,
      #abc862,
      yellow,
      #abc862,
      yellow,
      #abc862
    );
    content: "";
    position: absolute;
    top: -.2rem;
    left: -.2rem;
    background-size: 400%;
    width: calc(96%);
    height: calc(100% + .4rem);
    max-width: 6.12rem;
    z-index: -1;
    animation: animate 25s linear infinite;
    border-radius: 4px;
  }
}

.success-light {
  &::before,
  &::after {
    background: linear-gradient(
      45deg,
      green,
      #72fa79,
      green,
      #89f38e,
      green,
      #89f38e,
      green,
      #59f561
    );
    content: "";
    position: absolute;
    top: -.2rem;
    left: -.2rem;
    background-size: 400%;
    width: calc(96%);
    height: calc(100% + .4rem);
    max-width: 6.12rem;
    z-index: -1;
    animation: animate 25s linear infinite;
    border-radius: 4px;
  }
}

.error-light {
  &::before,
  &::after {
    background: linear-gradient(
      45deg,
      red,
      #fe5959,
      red,
      #f57777,
      red,
      #a02828,
      red,
      #a25b5b
    );
    content: "";
    position: absolute;
    top: -.2rem;
    left: -.2rem;
    background-size: 400%;
    width: calc(96%);
    height: calc(100% + .4rem);
    max-width: 6.12rem;
    z-index: -1;
    animation: animate 25s linear infinite;
    border-radius: 4px;
  }
}

.loading-light::after,
.error-light::after,
.success-light::after {
  filter: blur(8px);
}

@keyframes animate {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

//  background: linear-gradient(45deg,red,blue,green,yellow,#e11d74,black,#ffff00,#aa0000);

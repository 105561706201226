@import '../../style/sass/colors';

.filter-container {
    display: flex;
    flex-direction: row;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    align-items: center;
    justify-content: space-between;
    margin: 0.8rem 0;
    color: $secondary-color;

    .selected-filter-container {
        height: 4.7rem;
        flex: 1;
        margin: 0 0.8rem;
        padding: 0.3rem;
        border-radius: 0.6rem;
        border: 0.1rem solid $border-color-3;
        // overflow: auto;
        display: flex;
        align-items: center;
        gap: 0.625rem;

        ul {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            gap: 0.7rem;
            height: inherit;
            list-style: none;
            margin: 0 1rem 0 0;
            max-height: 7.5rem;
            overflow: hidden;
            padding: 0.5rem 0;
            scrollbar-gutter: stable both-edges;
            width: inherit;

            li {
                background: #efefef;
                border-radius: 0.3125rem;
                color: #1d1d1b;
                font-size: 0.95rem;
                line-height: 1.063rem;
                padding: 0.5rem;
            }

            .filter-li {
                margin: 0 0.5rem;
                position: relative;
            }

            .close-button {
                align-items: center;
                background-color: #efefef !important;
                background-image: none;
                border-radius: 50% !important;
                color: #939598 !important;
                display: flex;
                height: 1rem;
                justify-content: center;
                min-width: 0 !important;
                opacity: 1;
                position: absolute !important;
                right: -0.4rem;
                top: -0.4rem;
                width: 1.1rem;
                border: none;
                font-weight: 400;


                &:hover {
                    opacity: 1;
                    background-color: $active-close !important;
                    color: $primary-color;

                    .permission-clear {
                        color: $primary-color !important;
                    }
                }

                svg {
                    height: 0.1rem;
                }
            }
        }

        ul:hover {
            overflow-y: auto;
        }
    }

    .button-container {
        display: flex;
        gap: 0.8rem;

        .cancel-button {
            border: 0.063rem solid $primary-color;
            color: $primary-color;
        }
    }

    .d-flex {
        display: flex;
        align-items: center;
    }
}

.dropdown-container {
    position: relative;
}

.dropdown-container label {
    position: relative;
    z-index: 9;
    padding: 0 0.625rem;
    margin: 0 0.625rem;
    background: $white-color;
}

.dropdown-container .rmsc.multi-select {
    margin-top: -0.5rem;
}

.dropdown-container .dropdown-content {
    z-index: 99 !important;

    input[type="checkbox"]:checked {
        accent-color: $primary-color;
    }
}

.grid-con .clear-cta {
    text-align: right;

    button {
        background: $border-color;
        border-radius: 0.5rem;
        border: none;
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
        font-weight: 500;
        height: auto;
    }
}

.filter-drawer {
    .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper {
        padding: 20px;
        background-color: $white-color !important;
        width: 30rem;
    }

    .drawer-footer-button {
        display: flex;
        flex-direction: row-reverse;
        gap: 0.8rem;
        margin: 1rem 0;

        .cancel-button {
            border: 0.063rem solid $primary-color;
            color: $primary-color;
        }
    }

    .drawer-header-button {
        display: flex;
        justify-content: space-between;

        .left-section {
            display: flex;
            gap: 0.4rem;
            font-weight: 600;
            justify-content: center;
            align-items: center;
            font-size: 1.2rem;

        }

        .right-section {
            text-align: right;

            button {
                background: $border-color;
                border-radius: 0.5rem;
                border: none;
                padding: 0.5rem 1rem;
                font-size: 0.875rem;
                font-weight: 500;
                height: auto;
            }
        }
    }

    .grid-con-header {
        button.MuiIconButton-root {
            position: relative;
            left: -0.563rem;

            svg {
                font-size: 1.938rem;
            }
        }
    }

    .grid-con-box {
        border-bottom: 0.063rem solid $border-color;
        margin: 0;
        width: 100%;
        padding: 1rem 0;

        .p-heading {
            padding: 0;
            margin: 0;
            font-size: 1rem;
            color: $text-color-2;
            font-weight: 600;
        }

        .dropdown-container {
            margin: 1rem 0;

            label {
                color: $text-color;
                font-weight: 500;
                font-size: 1rem;
            }

            .rmsc.multi-select {
                .dropdown-heading {
                    line-height: 2rem;
                    height: 2.5rem;

                    .dropdown-heading-value {
                        border-right: 0.125rem solid $input-border-color;
                        margin-right: 0.438rem;
                    }
                }

                .dropdown-container {
                    margin-top: 0;
                    border-color: $input-border-color;
                    border-radius: 0.5rem;
                    padding: 0.25rem;
                    border-width: 0.1rem;

                    .dropdown-heading {
                        .dropdown-heading-value {
                            span {
                                color: $text-primary;
                            }

                            span.gray {
                                color: $text-color-3;
                            }
                        }
                    }
                }
            }
        }

        .dropdown-container:first-child {
            margin: 0.7rem 0;
        }
    }
}

.alerts-con-box {
    border-bottom: 0 !important;
}

.grid-con-box:last-child {
    border: none;
}

.rmsc .dropdown-container:focus-within {
    box-shadow: none;
}

// .filter-li {
//     position: relative;
//     margin: 0 0.5rem;

//     .permission-clear {
//         font-size: 0.75rem;
//     }

//     // .permission-clear:hover {}
// }
// $primary-color: #292666;
// $secondary-color: #364C61;
$primary-color: #E94F1C;
$secondary-color: #1D1D1B;
$table-header-1: #efefef;
$table-header-2: #999999;

$text-primary: #424242;
$text-color: #939598;
$text-color-1: #292666;
$text-color-2: #2A3F54;
$text-color-3: #8D939B;
$text-color-4: #808081;
$text-disabled: #ACB2BA;
$link-color: #F2F1FF;
$link-color-1: #F8F8F8;
$grey-1: #ACB2BA;
$grey-2: #D1D1D1;
$grey-3: #F2F2F2;
$grey-4: #F5F5F5;
$grey-5: #F1F1F1;
$grey-6: #f5f4f7;
$grey-7: #e7e5e5;
$grey-8: #dedfe9;
$grey-9: #8c919a;
$grey-10: #00000026;
$grey-11: #f8f8f8;
$grey-12: #acb2ba;
$grey-13: #cdd0d5;
$white-color: white;
$status-green: #1BD365;
$status-closed: #9E9E9E;
$approver-green: #50CE6C;
$approver-grey: #D5D9DD;
$status-red: #FF3C3C;
$status-orange: #B72121;
$delete-color: #EF3A3A;
$delete-color-2: #fa9191;
$status-blue: #5582AE;
$background-color: #fff;
$run-case: #3E8AEF;
$border-color: #EDEDED;
$border-color-3: #E6E6E6;
$copy-color: #21B79A;
$case-unfreeze: #88E4D2;
$case-unfreeze-text: #21B79A;
$case-freeze: #FFDBDB;
$case-freeze-text: #B72121;
$chart-deactivate-color: #8884d8;
$black: #000;
$tableRow: rgba(0, 0, 0, .04);
$groupTableRowPagination: rgba(0, 0, 0, .08);
$border-color2: #b6b6b4;
$list-active: #F2F1FF;
$selected-case-background: #DCEEFC;
$selected-case-color: #389BFF;
$not-selected-case-background: #EDEDED;
$not-selected-case-color: #2A3F54;
$table-border-color: #dee2e6;
$sale-table-bg-color: #353444;
$sale-table-border-color: #757575;
$sale-table-font-color: #b0afb2;
$reports-power-bi-container: #e2c243;
$reports-excel-container: #398140;
$reports-container: #f9f9fb;
$table-td-color: #212529;
$active-close: #fdbbac;
$input-border-color: rgba(0, 0, 0, 0.15);
$react-select-placeholder: rgb(51, 51, 51);
$role-permission-cross-background: rgb(255, 189, 173);
$tab-color: #5b5b5b;
$border-color-2: #00000014;
$link-button: #0d6efd;
$disabled-link-button: #4b91fa;


:export {
    primaryColor: $primary-color;
    chartDeactivateColor: $chart-deactivate-color;
    blackColor: $black;
}